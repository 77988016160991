#video-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  bottom:0;
}



#uiFeatures #controls {
display: none;
}

.xrBtn{
  display: none;
}
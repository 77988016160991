
/*

@media (max-width: 767px){
  .showOnlyLaptop{
    display: none;
  }
}
*/




.row{
  margin-left: 0;
  margin-right: 0;
}

  



@media (max-width: 915px) {
   
  .MobilUICode{
      display: block;
  }
  
  
 
  }
  
  
  
  @media (min-width: 915px) {
     
      .showOnlyLaptop{
         /* display: block;*/
      }
      
  
  
       .MobilUICode{
          display: none;
         }
  
  
      }
      
  
  












.BottomBoxUI {
    width: 100vw;  
    position: fixed;
    padding-bottom: 10px;
    bottom: 0;
    overflow: hidden; 
  }





  .Receive {
    padding-top: 3px;
   background: transparent;
   padding-left: 5px;
    color: #ffffff;
    font-size: 10px;
      align-self: flex-end;
      border-radius: 10px;
   }


   .You {
  
    padding-top: 3px;
    background: transparent;
    color: #ffffff;
   /* align-self: flex-end;*/
   align-self: flex-start;
    font-size: 10px;
    color: #EEEEF0;
  /*  border-radius: 10px;*/
   }



   .sent-message2 {
   /*  background-color: #ecebeb;    */
     background-color: #007AFF;
     align-self: flex-end;
     color:#ffffff;
     border-radius:  16px ; 
   }
   
   .received-message2 {
  
  background-color: #EEEEF0;
    color: #000000;
    border-radius: 16px;
   }





.text_hide{
  background: linear-gradient(to top,rgba(111, 105, 105, 0.3), rgba(135, 133, 133, 0.64));; 
 
  border-radius: 12px;

}


  

  ::-webkit-scrollbar {
    width: 0;
  }




  .chat-input2 input::placeholder {
    color: rgb(255, 251, 251); 
    font-size: 14px;
  }






#form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}


  .chat-message-container2 {
 
  /*  border-radius: 14px;*/
    display: flex;
    align-self: flex-start; 
   /* color: black;*/
  
  }
  




  .message-content2 {
     min-height: auto;
  
   }





   .message-time2 {
    text-align: end;
    font-size: 6px;
    padding-right: 10px;
   /* padding-bottom:5px;*/
 

  }













/* ============================== Mobile UI CSS    =========================================*/






@media screen and (orientation: portrait  ) {
  .Button_3_UI{
    position: fixed;
    right: 5%;
    top: 10%;
    border-radius: 12px 12px 12px 12px;
    background-color: rgba(48, 47, 47, 0.401);
    background: transparent;
    border: none;
    height:40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    
  }
  
  
  
  .TopButton{
    
      background-color:gray ;
      color: white;
      border:none;
      border-top-left-radius: 20%;
      border-top-right-radius: 20%;
      height:40px;
    
     background-color: rgba(48, 47, 47, 0.401);
    
    }
    
    
    
    .MidButton{
      background-color:gray ;
      color: white;
      border:none;
     
      height:40px;
 
     background-color: rgba(48, 47, 47, 0.401);
     
    
    
    }
    
    
    .BottomButton{
    
      background-color:gray ;
      color: white;
      border:none;
      border-bottom-left-radius: 20%;
      border-bottom-right-radius: 20%;
      height:40px;
 
     background-color: rgba(48, 47, 47, 0.401);
    
    
    }
  
  
  
  
  .chat-app {
     max-height: calc(92vh - 120px); 
     overflow-y: auto;
     scroll-behavior: smooth;
     position: fixed;
     top:80px;
     bottom: 40px;
     width: 100vw;
    
     z-index: 0;
     flex-direction: column-reverse;
     display: flex;


   }
   
  
  .inputTextBox{
    display: flex;
    border:2px solid black;
  }
  
  
  
  
  
  
  }
  
  
  
  
  
  #Box_Buttons_1{
    padding: 50%;
    font-size: small;
    background-color:gray ;
    color: white;
    border:none;
    border-radius: 50%;
   background-color: rgba(48, 47, 47, 0.401);
   font-size: 18px;
    
  }
  

  
  
  .mic_ChatBox {
    width: 100vw;
   
    position: fixed;
    padding-bottom: 10px;
    bottom: 0;
    overflow: hidden;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media screen and (orientation: landscape) {

      
  .chat-app {
    max-height: calc(92vh - 120px); 
    overflow-y: auto;
    scroll-behavior: smooth;
    position: fixed;
    top:80px;
    bottom: 40px;
    width: 100vw;
   
    z-index: 0;
    flex-direction: column-reverse;
    display: flex;


  }

  
  
  .chat-messages {

    flex: 1 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
  
  
  }

   
  .Button_3_UI{
    position: fixed;
    right: 10%;
    top: 5%;
    border-radius: 12px 12px 12px 12px;
    background-color: rgba(48, 47, 47, 0.401);
    background: transparent;
    border: none;
    height:40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center
    

  }
  
  
  
  .TopButton{
  
    background-color:gray ;
    color: white;
    border:none;
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
   background-color: rgba(48, 47, 47, 0.401);
   height:40px;
   
  }
  
  
  
  .MidButton{
    background-color:gray ;
    color: white;
    border:none;
   
    height:40px;
 
   background-color: rgba(48, 47, 47, 0.401);
   
  
  
  }
  
  
  .BottomButton{
   
    background-color:gray ;
    color: white;
    border:none;
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
    height:40px;
   
   background-color: rgba(48, 47, 47, 0.401);
  
  
  }
  
  
  
  

  

  
  
  
  }
  
  
  
  
  
  
  
  
  
  
  
  .backButton{
     border: none;
     background-color: #d9d9d954;
    border-radius: 20%;
    left:2%;
    top:2%;
    position: fixed;
    font-size: 24px;
     
  }
  
  
  
  
  
  .inputTextBox {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    background: #36454F;
  }
  
  .inputTextBox input {
    flex: 1; 
    border-radius: 20px;
    color: white;
    background: transparent;
  }
  
  .sendButton {
    margin-left: 10px;
    border-radius: 50%;
    border: none;
  }
  
  
  @media (max-width: 768px) {
    .inputTextBox {
      flex-direction: column;
      align-items: stretch;
    }
  
    .inputTextBox input {
      margin-bottom: 5px;
    }
  }
  
  
  
  
  
  
  
  

  
  .chat-messages {

    flex: 1 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
  
  
  }
  
  .chat-message-container {
  
   
    border-radius: 12px;
    display: flex;
    margin-bottom: 10px;
    align-self: flex-start;
    color: black;
  
  }
  
  
  
  
  .message-content {
    
     border-radius: 12px;
     max-height: calc(50vh - 70px); 
     overflow-y: auto;


     /* =====  Sir Style Code=======   */

      position: fixed;
        bottom: 2px;
 
       width:22%;
        margin-bottom: 55px;
       

   }
   
   .message-time {
    
     text-align: end;
     font-size: 12px;
     color: #000000;
     padding-right: 10px;
     padding-bottom:5px;
   }
   
  
  
  
  .chat-message {
    padding: 8px;
    border-radius: 8px;
    max-width: 80%; 
  }
  
  .sent-message {
  
 /*   background-color: #ecebeb;*/
 background-color: #007AFF;
    align-self: flex-end; 
    
  }
  
  .received-message {
 /*  background-color: #007bff;
 */

 background-color: gray;
   color: #fff;
  
  }
  
  
  
  
  
  
  
  
  
  .chat-input {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #f1f0f0;
  }
  
  .chat-input input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 20px;
    background-color: #fff;
  }
  
  .chat-input button {
    margin-left: 10px;
    background: #007bff;
    border: none;
    color: #fff;
    cursor: pointer;
  }
  
  
  
  
  
  
  
  
  
  @media (max-width: 768px) {
    .chat-message {
      max-width: 90%; 
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  .chat-input input {
    color: black; 
    font-size: 16px;
    padding: 10px; 
   
    border-radius: 5px; 
  }
  
  
  .chat-input input::placeholder {
    color: black; 
  }
  
  
  
  
  
  
  
  .Send {
      background: transparent;
      color: #ffffff;
     /* align-self: flex-end;  */
      align-self: flex-start;
      font-size: 12px;
      
     }
  
  
  























    em-emoji-picker {
      
      width: 100%;
      opacity: 0.7;
      max-height: calc(45vh); 
      
    /*  min-width: 250px;*/
      resize: horizontal;
      overflow: auto;
    }






/*     //Important style for Picker css(Emojis Box inside style)
    em-emoji-picker {
      --background-rgb: 85, 170, 255;
      --border-radius: 24px;
      --category-icon-size: 24px;
      --color-border-over: rgba(0, 0, 0, 0.1);
      --color-border: rgba(0, 0, 0, 0.05);
      --font-family: 'Comic Sans MS', 'Chalkboard SE', cursive;
      --font-size: 20px;
     --font-size: 12px;
      --rgb-accent: 255, 105, 180;
      --rgb-background: 262, 240, 283;
      --rgb-color: 102, 51, 153;
      --rgb-input: 255, 235, 235;
      --shadow: 5px 5px 15px -8px rebeccapurple;

      height: 50vh;
      min-height: 400px;
      max-height: 800px;
    }

*/
















.vertical-slider-container {
  width: 20px;
  height: 200px;
  background-color: #f0f0f0;
  position: relative;
  margin: 20px;
}

/* Override the slider styles to make it vertical */
.custom-slider {
  height: 100%;
  position: absolute;
}

.react-slider-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%) translateY(100%);
  transition: transform 0.2s ease; /* Add transition for smooth scrolling effect */
}

.custom-slider .react-slider-thumb.react-slider-thumb-0 {
  transform: translateX(-50%) translateY(0%);
}

















/*=====================   Add New CSS     ======================       */


.chatBoxUI {
  /* This will make the div display its contents in a block layout */
 
  border:"2px solid black";

}











/* For Desktop View */
@media screen and (min-width: 1024px) {
   
    .cardAvt{
     
        background-color: gray;
        width:100%
   }
   
   .readyPlayerClose{
    z-index:120;
    position:fixed;
    top:3.5%;
    left:12%;
    background:transparent;
    border:none;
   }
  
   .imgAvt{
       width:128px;
       height:128px;
      
   }
   .createBtn{
    margin-left: 12%;
    margin-top: 12%;
      font-size: 72px;
      color: #fff;
  }
  
  
  .cardBox{
      padding:1%
  }

}
 
/* For Tablet View */
@media screen and (min-device-width: 768px) 
    and (max-device-width: 1024px) {

        .cardBox{
            padding:3%
        }
       
        .cardAvt{
         
           height:80px;
             width:80px
        }

        .readyPlayerClose{
            z-index:120;
            position:fixed;
            top:8px;
            left:100px;
            background:transparent;
            border:none;
           }
      
        .createBtn{
            color: #fff;
            margin-left: 10%;
            margin-top: 8%;
            font-size: 64px;
        }
        
        .imgAvt{
            width:80px;
            height: 80px
        }
   
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) 
    and (orientation: portrait) {
       .cardBox{
        padding:3%
    }

    .readyPlayerClose{
        z-index:120;
        position:fixed;
        top:8px;
        left:100px;
        background:transparent;
        border:none;
       }
   
    .cardAvt{
     
       height:80px;
         width:80px
    }
  
    .createBtn{
       color: #fff;
        font-size: 48px;
        margin-top: 12%;
        margin-left: 25%;
    }
    
    .imgAvt{
        width:80px;
        height: 80px
    }
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 680px) 
    and (orientation: landscape) {
        .cardBox{
            padding:3%
        }

        .readyPlayerClose{
            z-index:120;
            position:fixed;
            top:8px;
            left:100px;
            background:transparent;
            border:none;
           }
       
        .cardAvt{
         
           height:80px;
             width:80px
        }
      
        .createBtn{
            margin-top: 12%;
            margin-left: 12%;
            font-size: 48px;
            color:#fff;
        }
        
        .imgAvt{
            width:80px;
            height: 80px
        }
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen
    and (max-device-width: 640px) {
   
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) 
    and (-webkit-min-device-pixel-ratio: 2) {
   
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) 
    and (device-width: 320px) 
    and (-webkit-min-device-pixel-ratio: 2) {
 
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) 
    and (min-device-width: 375px) 
    and (-webkit-min-device-pixel-ratio: 3) {
        
    
};




#modelviewer {
    height: 100px;
    width: 100px;
    border-radius: 12px;
   
}

.ant-card .ant-card-body {
         padding: 0px; 
     border-radius: 0px;
}

.cardBtn{
    border-radius: 50%;
}
.ant-drawer .ant-drawer-header {
z-index: 2000;
}










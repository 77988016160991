


.ant-card .ant-card-body {
    padding: 0px; 
border-radius: 0px;
}



.cardAvt2{

 background-color: gray;
 width:100%;
 height: 90px;
}



.imgAvt2{
width:100%;
height: 90px;

}



.cardBox2{
padding:1%
}


@media (max-width: 450px) {
.cardBox2{
   padding:1%
}

.cardAvt2{

  height:100px;
    width:100px
}



.imgAvt2{
   width:100px;
   height: 90px
}




}















/*  ======= HelpUI.js ================ */

.my-custom-button {
   padding:6px;
    border:2px solid black;
    border-radius:5px;
    font-weight: bold;


 }



 .rowBox{
   width:100%;
   height:100px;

 }

 .colBox{
   width:100%;
   padding-top:10px;
    padding-bottom:10px;
 }
